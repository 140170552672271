var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12 col-lg-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "화학자재 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.deleteEditable,
                                expression: "deleteEditable",
                              },
                            ],
                            attrs: {
                              url: _vm.deleteUrl,
                              isSubmit: _vm.isDelete,
                              mappingType: "DELETE",
                              label: "삭제",
                              icon: "remove",
                            },
                            on: {
                              beforeAction: _vm.removeReview,
                              btnCallback: _vm.removeReviewCallback,
                            },
                          }),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.review,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-material", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.disabled || _vm.isOld,
                            data: _vm.review,
                            reviewCheck: true,
                            isWrite: true,
                            label: "자재코드/자재명(KOR)/자재명(ENG)",
                            name: "materialCd",
                            type: "codenameen",
                          },
                          on: {
                            "update:data": function ($event) {
                              _vm.review = $event
                            },
                          },
                          model: {
                            value: _vm.review.materialCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "materialCd", $$v)
                            },
                            expression: "review.materialCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "검토요청일",
                            name: "reviewRequestDate",
                          },
                          model: {
                            value: _vm.review.reviewRequestDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "reviewRequestDate", $$v)
                            },
                            expression: "review.reviewRequestDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-multi-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isArray: false,
                            codeGroupCd: "PLANT_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "관련사업장",
                            name: "plantCds",
                          },
                          model: {
                            value: _vm.review.plantCds,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "plantCds", $$v)
                            },
                            expression: "review.plantCds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            comboItems: _vm.mixFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "mixFlag",
                            label: "물질구성",
                          },
                          model: {
                            value: _vm.review.mixFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "mixFlag", $$v)
                            },
                            expression: "review.mixFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "공급사",
                            name: "deliveryVendorCd",
                          },
                          on: {
                            vendorName: (val) => {
                              _vm.review.deliveryVendorName = val
                            },
                          },
                          model: {
                            value: _vm.review.deliveryVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "deliveryVendorCd", $$v)
                            },
                            expression: "review.deliveryVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c(
                          "c-vendor",
                          {
                            attrs: {
                              editable: _vm.editable,
                              disabled: _vm.disabled,
                              label: "제조사",
                              name: "mfgVendorCd",
                            },
                            on: {
                              vendorName: (val) => {
                                _vm.review.mfgVendorName = val
                              },
                            },
                            model: {
                              value: _vm.review.mfgVendorCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.review, "mfgVendorCd", $$v)
                              },
                              expression: "review.mfgVendorCd",
                            },
                          },
                          [_vm._v("> ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "MSDS No.",
                            name: "msdsNo",
                          },
                          model: {
                            value: _vm.review.msdsNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "msdsNo", $$v)
                            },
                            expression: "review.msdsNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            codeGroupCd: "PROPERTIES_STATE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "propertiesStateCd",
                            label: "성상",
                          },
                          model: {
                            value: _vm.review.propertiesStateCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "propertiesStateCd", $$v)
                            },
                            expression: "review.propertiesStateCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "비중",
                            name: "specificGravity",
                          },
                          model: {
                            value: _vm.review.specificGravity,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "specificGravity", $$v)
                            },
                            expression: "review.specificGravity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "분자량",
                            name: "moleWeight",
                          },
                          model: {
                            value: _vm.review.moleWeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "moleWeight", $$v)
                            },
                            expression: "review.moleWeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "비산성(고체)",
                            name: "fugacity",
                          },
                          model: {
                            value: _vm.review.fugacity,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "fugacity", $$v)
                            },
                            expression: "review.fugacity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "끓는점(액체,기체)",
                            name: "boilPoint",
                          },
                          model: {
                            value: _vm.review.boilPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "boilPoint", $$v)
                            },
                            expression: "review.boilPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "녹는점",
                            name: "meltingPoint",
                          },
                          model: {
                            value: _vm.review.meltingPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "meltingPoint", $$v)
                            },
                            expression: "review.meltingPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            codeGroupCd: "USAGE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "usageCd",
                            label: "용도",
                          },
                          model: {
                            value: _vm.review.usageCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "usageCd", $$v)
                            },
                            expression: "review.usageCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable:
                              _vm.editable &&
                              _vm.review.usageCd == "UC00000032",
                            disabled: _vm.disabled,
                            label: "용도(기타)",
                            name: "usageEtc",
                          },
                          model: {
                            value: _vm.review.usageEtc,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "usageEtc", $$v)
                            },
                            expression: "review.usageEtc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "CAS No.",
                            name: "casNo",
                          },
                          model: {
                            value: _vm.review.casNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "casNo", $$v)
                            },
                            expression: "review.casNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "고유번호",
                            name: "serialNo",
                          },
                          model: {
                            value: _vm.review.serialNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "serialNo", $$v)
                            },
                            expression: "review.serialNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "KE번호",
                            name: "keNo",
                          },
                          model: {
                            value: _vm.review.keNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "keNo", $$v)
                            },
                            expression: "review.keNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "HS번호",
                            name: "hsNo",
                          },
                          model: {
                            value: _vm.review.hsNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "hsNo", $$v)
                            },
                            expression: "review.hsNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-12 col-lg-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "구매 전 신고/등록/인허가 사항" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isFlag: true,
                            label: "인허가대상 물질 여부",
                            name: "licensingFlag",
                          },
                          model: {
                            value: _vm.review.licensingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "licensingFlag", $$v)
                            },
                            expression: "review.licensingFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isFlag: true,
                            label: "위험물 여부",
                            name: "dangerFlag",
                          },
                          model: {
                            value: _vm.review.dangerFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "dangerFlag", $$v)
                            },
                            expression: "review.dangerFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isFlag: true,
                            label: "유해화학물질(PSM) 여부",
                            name: "psmFlag",
                          },
                          model: {
                            value: _vm.review.psmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "psmFlag", $$v)
                            },
                            expression: "review.psmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isFlag: true,
                            label: "화평법 등록 여부(제조/수입)",
                            name: "chemSubsControlFlag",
                          },
                          model: {
                            value: _vm.review.chemSubsControlFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "chemSubsControlFlag", $$v)
                            },
                            expression: "review.chemSubsControlFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isFlag: true,
                            label: "화학물질확인증명 여부(제조/수입)",
                            name: "chemAuthFlag",
                          },
                          model: {
                            value: _vm.review.chemAuthFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "chemAuthFlag", $$v)
                            },
                            expression: "review.chemAuthFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isFlag: true,
                            label:
                              "유해화학물질 신고여부(사용/제조/보관/저장/판매)",
                            name: "chemReportFlag",
                          },
                          model: {
                            value: _vm.review.chemReportFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "chemReportFlag", $$v)
                            },
                            expression: "review.chemReportFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isFlag: true,
                            label:
                              "유해화학물질 인허가 여부(사용/제조/보관/저장/판매)",
                            name: "chemLicenseFlag",
                          },
                          model: {
                            value: _vm.review.chemLicenseFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "chemLicenseFlag", $$v)
                            },
                            expression: "review.chemLicenseFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            comboItems: _vm.msdsFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "msdsFlag",
                            label: "MSDS제출여부",
                          },
                          model: {
                            value: _vm.review.msdsFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "msdsFlag", $$v)
                            },
                            expression: "review.msdsFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v(_vm._s(_vm.$language("제품 분류")) + " "),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("제조"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.makeFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "makeFlag", $$v)
                            },
                            expression: "review.makeFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("수입"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.impFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "impFlag", $$v)
                            },
                            expression: "review.impFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("구매"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.buyFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "buyFlag", $$v)
                            },
                            expression: "review.buyFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("사용"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.usingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "usingFlag", $$v)
                            },
                            expression: "review.usingFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("수출"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.expFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "expFlag", $$v)
                            },
                            expression: "review.expFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("판매"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.salesFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "salesFlag", $$v)
                            },
                            expression: "review.salesFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        staticStyle: { "margin-bottom": "10px" },
                      },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v(
                            _vm._s(_vm.$language("유해화학물질 포함 여부")) +
                              " "
                          ),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("유독물질"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.toxicPoisonFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "toxicPoisonFlag", $$v)
                            },
                            expression: "review.toxicPoisonFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("허가물질"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.toxicPermitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "toxicPermitFlag", $$v)
                            },
                            expression: "review.toxicPermitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("제한물질"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.toxicLimitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "toxicLimitFlag", $$v)
                            },
                            expression: "review.toxicLimitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            dense: "",
                            color: "orange-custom",
                            label: _vm.$language("금지물질"),
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.review.toxicProhibitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "toxicProhibitFlag", $$v)
                            },
                            expression: "review.toxicProhibitFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c(_vm.checkComponent, {
                          tag: "component",
                          attrs: {
                            popupParam: _vm.popupParam,
                            review: _vm.review,
                            disabled: _vm.disabled,
                            btnEditable: _vm.btnEditable,
                            contentHeight: _vm.contentHeight,
                          },
                          on: {
                            "update:review": function ($event) {
                              _vm.review = $event
                            },
                            "update:disabled": function ($event) {
                              _vm.disabled = $event
                            },
                            "update:btnEditable": function ($event) {
                              _vm.btnEditable = $event
                            },
                            "update:btn-editable": function ($event) {
                              _vm.btnEditable = $event
                            },
                            "update:contentHeight": function ($event) {
                              _vm.contentHeight = $event
                            },
                            "update:content-height": function ($event) {
                              _vm.contentHeight = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }